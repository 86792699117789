<template>
  <div class="main-body">
    <a-form :model="formState" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-item label="网站标题">
        <a-input v-model:value="formState.title" placeholder="请输入网站标题" />
      </a-form-item>

      <a-form-item label="网站logo">
        <CsImage v-model:imgOne="formState.logo" />
      </a-form-item>
      <a-form-item label="网站关键字" extra="多个关键字之间用英文“,”隔开">
        <a-input v-model:value="formState.keywords" placeholder="请输入网站关键字" />
      </a-form-item>
      <a-form-item label="网站描述">
        <a-textarea v-model:value="formState.description" placeholder="请输入网站描述" />
      </a-form-item>
      <a-form-item label="系统状态">
        <a-switch v-model:checked="formState.status" />
      </a-form-item>
      <a-form-item :wrapper-col="{ span: 7, offset: 3 }">
        <a-button type="primary" @click="onSubmit">保存</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>
<script>
import { defineComponent, reactive, onMounted } from "vue";
import CsImage from "@/components/UpImage";
import * as Api from "@/views/setting/api";
import { message } from "ant-design-vue";

export default defineComponent({
  components: {
    CsImage,
  },
  setup() {
    const formState = reactive({
      title: "",
      logo: "",
      keywords: "",
      description: "",
      status: true,
    });

    const onSubmit = () => {
      Api.save_setting(formState).then((res) => {
        message.success(res.message);
      });
    };

    const getInitSetting = () => {
      Api.get_global_setting().then((res) => {
        formState.title = res.data.title ? res.data.title : "";
        formState.logo = res.data.logo ? res.data.logo : "";
        formState.keywords = res.data.keywords ? res.data.keywords : "";
        formState.description = res.data.description
          ? res.data.description
          : "";
        formState.status = res.data.status == "1" ? true : false;
      });
    };

    onMounted(() => {
      getInitSetting();
    });

    return {
      labelCol: {
        span: 3,
      },
      wrapperCol: {
        span: 7,
      },
      formState,
      onSubmit,
    };
  },
});
</script>